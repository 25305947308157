/* Imports */
import { createRouter, createWebHistory } from 'vue-router';
import Routes from './routes';
import scrollBehavior from './scrolling';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior,
  routes: Routes,
});

export default router;
