<script setup>
/* Imports */
import {
  computed,
  onBeforeMount,
  ref,
} from 'vue';

/* Helpers */
import {
  mapActions,
} from '../helpers/mainHelpers';

/* Store - Actions */
const {
  fetchPrice,
} = mapActions();

/* Props */
const props = defineProps({
  map: {
    type: Object,
    default: () => {},
  },
});

/* Pricing Data */
const priceIsLoading = ref(true);
const pricingData = ref({});

/* Prices */
const listPrice = computed(() => (pricingData?.value?.listPrice ? pricingData?.value.listPrice : ''));
const salePrice = computed(() => (pricingData?.value?.salePrice ? pricingData?.value.salePrice : ''));
const hasDiscount = computed(() => {
  if (pricingData?.value?.unformatted) {
    return pricingData?.value.unformatted?.listPrice
        > pricingData?.value.unformatted?.salePrice;
  }

  return false;
});
const hasPrice = computed(() => pricingData?.value?.listPrice || pricingData?.value?.salePrice);
const discountedPrice = computed(() => (hasDiscount.value ? salePrice.value : undefined));
const hasPricingTextBefore = computed(() => (pricingData?.value?.andUpText && pricingData?.value?.andUpText.endsWith('{0}')));
const pricingText = computed(() => (pricingData?.value?.andUpText ? pricingData?.value.andUpText.replace('{0}', '') : ''));

/* Lifecycle - Before Mount - Load the number of updates */
onBeforeMount(async () => {
  try {
    const skuList = props?.map?.skuList || [];
    const pricingDataResult = await fetchPrice(skuList);
    pricingData.value = pricingDataResult;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  } finally {
    priceIsLoading.value = false;
  }
});
</script>

<template>
  <div class="price__container">
    <g-loader
      v-if="priceIsLoading"
      class="price__loader"
      type="dark"
    />
    <div v-else>
      <template v-if="hasPricingTextBefore && hasPrice">
        <div
          class="price-from-text"
        >
          {{ pricingText }}
        </div>
        <g-price
          class="price"
          :original-price="listPrice"
          :discounted-price="discountedPrice"
          :has-text="true"
        />
      </template>
      <template v-else-if="hasPrice">
        <g-price
          class="price"
          :original-price="listPrice"
          :discounted-price="discountedPrice"
          :has-text="true"
          :and-up-text="pricingText"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.price__container {
  margin-bottom: 0.5rem;
}

.price__loader {
  width: 5rem;
  padding-bottom: 0.5rem; // Used to create equal white-space below loader to match white-space above it
}

.price {
  font-size: 1.125rem;
}
</style>

<style lang="scss">
.g__price {
  display: inline-flex;
}

.price-from-text {
  @include font-primary-weight-bold();
  display: inline;
}
</style>
