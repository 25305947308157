/* Imports */
import Geohash from 'latlon-geohash';

/**
 * Computes the Latitude and Longitude based on Geohash key query param
 * @returns {Array<number> | null} An array containing latitude and longitude values
 */
export const getLatLngFromURL = () => {
  const searchParams = new URLSearchParams(window?.location?.search);
  const keyQueryParam = searchParams.get('key');

  if (keyQueryParam) {
    try {
      const latlon = Geohash.decode(keyQueryParam);
      const latValue = parseFloat(latlon.lat);
      const longValue = parseFloat(latlon.lon);
      return [latValue, longValue];
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error, 'Unable to parse geohash');
      return null;
    }
  }

  return null;
};

export default getLatLngFromURL;
