<script setup>
/* Imports */
import {
  computed,
  inject,
} from 'vue';

/* Helpers */
import {
  convertUnit,
  mapActions,
  mapGetters,
} from '../helpers/mainHelpers';

/* Icons */
import ArrowDown from '@garmin/regalia/icons/arrow-down/icon.vue';
import '@garmin/regalia/icons/map/icon';
import CloseIconMapSettings from '../components/icons/CloseIconMapSettings.vue';

/* Components */
import SafetyDepthSlider from '../components/SafetyDepthSlider.vue';
import MapViewOptions from '../components/MapViewOptions.vue';
import MapChartType from '../components/MapChartType.vue';
import MapSettingsDepthUnit from '../components/MapSettingsDepthUnit.vue';

/* Composables */
import { useRouteQuery } from '@vueuse/router';
import { useScreenWidth } from '../composables/useScreenWidth';

/* Initialize composables */
const { isMobile } = useScreenWidth();
const heatmapQueryParam = useRouteQuery('heatmap');

/* Inject */
const isHeatmap = inject('isHeatmap');

/* Store - Getters */
const {
  getIsDrawerVisible: isDrawerVisible,
  getIsMapSettingsOpen: isMapSettingsOpen,
} = mapGetters();

/* Store - Actions */
const {
  setChartType,
  setChartDepth,
  setSafetyDepth,
  setIsDrawerVisible,
  setMapHeight,
  setIsMapSettingsOpen,
} = mapActions();

/* Props */
const props = defineProps({
  openPosition: {
    type: String,
    default: '',
  },
  translations: {
    type: Object,
    default: () => ({}),
  },
  mapViewType: {
    type: String,
    default: '',
  },
  chartType: {
    type: String,
    default: '',
  },
  depthUnit: {
    type: String,
    default: '',
  },
  safetyDepth: {
    type: [String, Number],
    default: '',
  },
});

/* Dropdown Arrow Computed Classes */
const dropdownArrowClasses = computed(() => ({
  'map-settings__arrow': true,
  'map-settings__arrow--active': isMapSettingsOpen.value,
}));

/* Map Settings Container Classes */
const mapSettingsClass = computed(() => ({
  'map-settings__container': true,
  'map-settings__container--is-heatmap': isHeatmap.value,
  // These are still used for Desktop and Tablet
  'map-settings__container--top': props.openPosition === 'top',
  'map-settings__container--bottom': props.openPosition === 'bottom',
}));

const defaultMobileMapViewerHeight = 387;

/* Toggle Map Settings -> Click or Enter */
const toggleMapSettings = () => {
  setIsMapSettingsOpen(!isMapSettingsOpen.value);

  if (isMobile.value) {
    setIsDrawerVisible(!isDrawerVisible.value);
    const headerHeight = document.querySelector('.gh__header') ? document.querySelector('.gh__header').offsetHeight : 0;
    const headerBanner = document.querySelector('.m__header') ? document.querySelector('.m__header').offsetHeight : 0;
    const mapViewerHeight = window.innerHeight - headerHeight - headerBanner;
    if (isDrawerVisible.value) {
      setMapHeight(defaultMobileMapViewerHeight);
    } else {
      setMapHeight(mapViewerHeight);
    }
  }
};

/* Change Chart Type -> Garmin - 'nav' or 'fish' | AnotherBrand - 'nautical' or 'sonar' */
const changeChartType = (e) => setChartType(e);

/* Change Safety Depth -> Slider - Range */
const changeSafetyDepth = (e) => setSafetyDepth(e);

/* Handle View Type Change */
const changeMapViewType = (e) => {
  // Update heatmap URL query param value
  heatmapQueryParam.value = e === 'heatmap' ? 'true' : 'false';
};

/* Change Chart Depth -> Radio Buttons */
const changeChartDepth = (e) => {
  setSafetyDepth(convertUnit(props.safetyDepth, props.depthUnit, e));
  setChartDepth(e);
};
</script>

<template>
  <div
    class="map-settings__notice"
    data-testid="map-settings"
    tabindex="0"
    @click="toggleMapSettings"
    @keydown.enter="toggleMapSettings"
  >
    <template v-if="isMobile">
      <g-icon-map
        :aria-label="props.translations.ITFE_MARINE_MAPS_MAP_OPTIONS"
        class="map-settings__icon"
      />
    </template>
    <template v-else>
      <g-copy
        type="detail"
        class="map-settings__dropdown-title"
      >
        {{ props.translations.ITFE_MARINE_MAPS_MAP_OPTIONS }}
      </g-copy>

      <ArrowDown :class="dropdownArrowClasses" />
    </template>

    <Teleport
      to="#map-viewer__wrap"
      :disabled="!isMobile"
    >
      <div
        v-if="isMapSettingsOpen"
        :class="mapSettingsClass"
        @click.stop
      >
        <CloseIconMapSettings
          v-if="isMobile"
          :is-mobile="isMobile"
          :toggle-map-settings="toggleMapSettings"
        />

        <MapViewOptions
          :translations="props.translations"
          :map-view-type="props.mapViewType"
          @change-map-view="changeMapViewType($event)"
        />

        <MapChartType
          :translations="props.translations"
          :chart-type="props.chartType"
          @change-chart-type="changeChartType($event)"
        />

        <MapSettingsDepthUnit
          :translations="props.translations"
          :depth-unit="props.depthUnit"
          @change-chart-depth="changeChartDepth($event)"
        />

        <SafetyDepthSlider
          :safety-depth="props.safetyDepth"
          :depth-unit="props.depthUnit"
          :translations="props.translations"
          @change-safety-depth="changeSafetyDepth($event)"
        />
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
.map-settings {

  &__notice {
    background: $color-white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    padding: 0.5rem 0.5rem 0.15rem 0.5rem;
    @include drop-shadow();
    width: 3.125rem; // 50px
    height: 3.125rem; // 50px

    @include breakpoint('sm') {
      @include font-primary();
      @include font-primary-weight-medium();
      border-radius: 0;
      padding: 0.375rem 0.3125rem;
      font-size: 0.813rem;
      position: relative;
      margin: 1rem;
      border: 1px $color-black solid;
      box-shadow: none;
      width: auto;
      height: auto;
    }
  }

  &__arrow {
    position: relative;
    margin: 0 10px;
    transition: transform 0.2s ease;
    width: 0.75rem;
    height: 0.75rem;
    left: 3px;

    &--active {
      position: relative;
      margin: 0 10px;
      width: 0.75rem;
      height: 0.75rem;
      transition: transform 0.2s ease;
      transform: rotate(-180deg);

      @include breakpoint('sm') {
        display: block;
      }
    }

    @include breakpoint('sm') {
      display: block;
    }
  }

  &__dropdown-title {
    display: block;
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 0.8125rem;
    text-transform: uppercase;
  }

  &__container {
    display: flex;
    flex-direction: column;
    background: $color-white;
    height: 100%;
    position: absolute;
    z-index: 1001;
    top: 0;
    width: 100%;
    padding: 2rem;

    @include breakpoint('sm') {
      height: unset;
      z-index: 1;
      top: unset;
      width: 14rem;
      padding: 15px;

      &--is-heatmap {
        padding: 15px 15px 0;
      }
    }

    &--top {
      bottom: 0;
      right: 0;
      margin-bottom: 40px;
    }

    &--bottom {
      top: 0;
      left: 0;
      margin-top: 40px;
    }
  }
}
</style>

<style lang="scss">
.map-settings {

  &__icon > svg {
    height: 1.5625rem; // 25px
    fill: $color-black;
  }
}
</style>
