/* Imports */
import Geohash from 'latlon-geohash';
import router from '../router/router';
import {
  getLatLngFromURL,
  getUserGeolocation,
} from '../helpers/mainHelpers';

// Default coordinates to Lebanon, KS
const defaultMapCenter = [39.8097343, -98.5556199];

export default {
  state: {
    mapCenter: defaultMapCenter,
    latLng: {
      lat: defaultMapCenter[0],
      lng: defaultMapCenter[1],
    },
    worldwideCoordinates: {
      top: 90,
      bottom: -90,
      left: -180,
      right: 180,
    },
    bounds: null,
    locationMarker: null,
    showLocationMarker: true,
    polygon: {},
    partNumber: '',
    googleMapsApiKey: 'AIzaSyDU37dtb0iX3gJJXTPHlBcTEoXBZoyHVgc',
    googleMapsApi: null,
    zoom: 5,
    garminTileToken: '',
    navionicsTileToken: '',
    worldwideNumberOfUpdates: null,
    polygonNumberOfUpdates: null,
    isInitialMapLocationSet: false,
    mapHeight: 500,
  },
  getters: {
    getIsInitialMapLocationSet: (currentState) => currentState.isInitialMapLocationSet,
    getMapCenter: (currentState) => currentState.mapCenter,
    getLatLng: (currentState) => currentState.latLng,
    getWorldwideCoordinates: (currentState) => currentState.worldwideCoordinates,
    getBounds: (currentState) => currentState.bounds,
    getPolygon: (currentState) => currentState.polygon,
    getPartNumber: (currentState) => currentState.partNumber,
    getGoogleMapsApiKey: (currentState) => currentState.googleMapsApiKey,
    getGoogleMapsApi: (currentState) => currentState.googleMapsApi,
    getZoom: (currentState) => currentState.zoom,
    getLocationMarker: (currentState) => currentState.locationMarker,
    getShowLocationMarker: (currentState) => currentState.showLocationMarker,
    getGarminTileToken: (currentState) => currentState.garminTileToken,
    getNavionicsTileToken: (currentState) => currentState.navionicsTileToken,
    getWorldwideNumberOfUpdates: (currentState) => currentState.worldwideNumberOfUpdates,
    getPolygonNumberOfUpdates: (currentState) => currentState.polygonNumberOfUpdates,
    getMapHeight: (currentState) => currentState.mapHeight,
  },
  mutations: {
    setIsInitialMapLocationSet(state, isInitialMapLocationSet) {
      state.isInitialMapLocationSet = isInitialMapLocationSet;
    },
    setMapCenter(state, mapCenter) {
      state.mapCenter = mapCenter;
    },
    setLatLng(state, latLng) {
      state.latLng = latLng;
    },
    setBounds(state, bounds) {
      state.bounds = bounds;
    },
    setLocationMarker(state, payload) {
      state.locationMarker = payload;
    },
    setShowLocationMarker(state, payload) {
      state.showLocationMarker = payload;
    },
    setPolygon(state, polygon) {
      state.polygon = polygon;
    },
    setPartNumber(state, partNumber) {
      state.partNumber = partNumber;
    },
    setGoogleMapsApi(state, payload) {
      state.googleMapsApi = payload;
    },
    setZoom(state, payload) {
      state.zoom = payload;
    },
    setGarminTileToken(state, payload) {
      state.garminTileToken = payload;
    },
    setNavionicsTileToken(state, payload) {
      state.navionicsTileToken = payload;
    },
    setWorldwideNumberOfUpdates(state, payload) {
      state.worldwideNumberOfUpdates = payload;
    },
    setPolygonNumberOfUpdates(state, payload) {
      state.polygonNumberOfUpdates = payload;
    },
    setMapHeight(state, payload) {
      state.mapHeight = payload;
    },
  },
  actions: {
    /*
     * Due to an issue in Vue-Leaflet: https://github.com/vue-leaflet/vue-leaflet/issues/305
     * Updates to the center prop on the <L-Map /> do not trigger a repaint/reposition of the map
     * Based on this, we need to set the correct center coordinates based on the key query param,
     * shared navigator.geolocation, or the default map center when we initial set the <L-Map />
     * center prop.
     */
    async setInitialMapLocation({ commit }) {
      const urlLatLng = getLatLngFromURL();
      try {
        // Use coordinates from URL query param if possible, otherwise, use navigator.geolocation if possible
        const position = await getUserGeolocation();
        const centerLatLng = urlLatLng || position;
        commit('setMapCenter', centerLatLng);
      } catch (error) {
        // If navigator.geolocation is unavailable (browser does not support it or user does not share it)
        // Use coordinates from URL query param if possible, otherwise, use the default map center coordinates
        const centerLatLng = urlLatLng || defaultMapCenter;
        commit('setMapCenter', centerLatLng);
      } finally {
        // After location is determined, then we can do the initial render of the leaflet map
        commit('setIsInitialMapLocationSet', true);
      }
    },
    setMapCenter({ commit }, payload) {
      commit('setMapCenter', payload);
    },
    setLatLng({ commit }, payload) {
      commit('setLatLng', payload);
    },
    setBounds({ commit }, payload) {
      commit('setBounds', payload);
    },
    async setLocationMarker({ commit }, payload) {
      commit('setLocationMarker', payload);
    },
    setShowLocationMarker({ commit }, payload) {
      commit('setShowLocationMarker', payload);
    },
    setPolygon({ commit }, payload) {
      commit('setPolygon', payload);
    },
    removePolygon({ commit }) {
      commit('setPolygon', null);
      commit('setPartNumber', null);
    },
    setPartNumber({ commit }, payload) {
      commit('setPartNumber', payload);
    },
    setZoom({ commit }, payload) {
      commit('setZoom', payload);
    },
    async updateMapInfo({ dispatch }, { lat, lng, bounds }) {
      dispatch('setLatLng', { lat, lng });
      dispatch('setBounds', bounds);
      dispatch('setLocationMarker', { lat, lng });
      dispatch('updateUrlForMap');
      dispatch('setShowInstructions', false);
      // search for product information
      // this will break is the component order is changed at all
      dispatch('searchMap');
    },
    updateUrlForMap({ getters }) {
      const { getLatLng } = getters;
      const geohash = Geohash.encode(getLatLng.lat, getLatLng.lng);
      const existingQueryParams = Object.fromEntries(new URLSearchParams(window?.location?.search));
      // update key query param
      router.push({
        query: { ...existingQueryParams, key: geohash },
      });
    },
    setGoogleMapsApi({ commit }, payload) {
      commit('setGoogleMapsApi', payload);
    },
    async fetchNumberOfUpdateMultiPolygon({ commit }, payload) {
      const {
        from,
        to,
        coordinates,
      } = payload;

      const noUpdatesPolygonUrl = 'https://backend-heatmap.navionics.com/charts/updates/heatmap/get_updates_counter_in_geojson';
      const geometries = coordinates.map((polygon) => ({
        type: 'Polygon',
        coordinates: [polygon],
      }));
      const requestBody = {
        from,
        to,
        selection: `{
          type: "FeatureCollection",
          features:[{
            type: "Feature",
            geometry:{
              type: "GeometryCollection",
              geometries: ${JSON.stringify(geometries)},
            }
          }]
        }`,
      };

      try {
        const response = await fetch(noUpdatesPolygonUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });
        if (!response.ok) {
          throw new Error(`Received ${response.status}: ${response.statusText}`);
        }
        const result = await response.text();
        commit('setPolygonNumberOfUpdates', result);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch number of updates by poligon with error: ${error}`);
      }
    },
    async fetchWorldwideNumberOfUpdates({ commit, getters }, payload) {
      const {
        from,
        to,
      } = payload;
      const worldwideRectCoordinates = getters.getWorldwideCoordinates;

      const queryParams = new URLSearchParams();
      queryParams.append('top', worldwideRectCoordinates.top);
      queryParams.append('bottom', worldwideRectCoordinates.bottom);
      queryParams.append('left', worldwideRectCoordinates.left);
      queryParams.append('right', worldwideRectCoordinates.right);
      queryParams.append('from', from);
      queryParams.append('to', to);
      const noUpdatesRectangleUrl = `https://backend-heatmap.navionics.com/charts/updates/heatmap/get_updates_counter_in_rect?${queryParams}`;

      try {
        const response = await fetch(noUpdatesRectangleUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error(`Received ${response.status}: ${response.statusText}`);
        }
        const result = await response.text();
        commit('setWorldwideNumberOfUpdates', result);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch number of worldwide updates with error: ${error}`);
      }
    },
    async fetchGarminTileToken({ commit, rootGetters }) {
      await rootGetters;
      const {
        getGarminTileDomain: garminTileDomain,
        getTileApiKey: apiData,
      } = rootGetters;
      const { url, password } = apiData;

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Origin: garminTileDomain,
            'X-API-Key': password,
          },
        });
        if (!response.ok) {
          throw new Error(`Received ${response.status}: ${response.statusText}`);
        }
        const result = await response.text();
        commit('setGarminTileToken', result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch Garmin tile token with error ${e}`);
      }
    },
    async fetchNavionicsTileToken({ commit, rootGetters }) {
      await rootGetters;
      const {
        getNavionicsTileApiKey: apiData,
      } = rootGetters;
      const { password } = apiData;
      const { hostname } = window.location;
      const navionicsTileAccessTokenUrl = `https://tile1.navionics.com/tile/get_key/${password}/${hostname}`;

      try {
        const response = await fetch(navionicsTileAccessTokenUrl, {
          method: 'GET',
          headers: {
            Accept: 'text/plain',
          },
        });
        if (!response.ok) {
          throw new Error(`Received ${response.status}: ${response.statusText}`);
        }
        const result = await response.text();
        commit('setNavionicsTileToken', result);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Unable to fetch Navionics tile token with error ${e}`);
      }
    },
    setMapHeight({ commit }, payload) {
      commit('setMapHeight', payload);
    },
  },
};
